import { Grid, GridItem, Divider, Text, Checkbox, Box, Link, useColorModeValue } from '@chakra-ui/react';
import { ChangeEvent, ReactNode } from 'react';

import { Info } from '@/components/shared/Info';
import { useOffers } from '@/contexts/OfferContext';
import { OfferToggle } from '@/components/shared/OfferToggle';

export const EntryRow = ({
  children,
  title,
  subtitle,
  href,
  description,
  onChange,
  enabled,
  readonly,
  name,
  highlighted,
  hasAccent,
}: {
  children: ReactNode;
  title: string;
  subtitle?: string;
  href?: string;
  description?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  enabled?: boolean;
  readonly?: boolean;
  name?: string;
  highlighted?: boolean;
  hasAccent?: boolean;
}) => {
  const { getFieldOfferState } = useOffers();
  const { isInternalHidden, isHidden, isReadOnly } = getFieldOfferState(name);
  const detailColor = useColorModeValue(hasAccent ? 'gray.200' : 'gray.500', hasAccent ? 'gray.800' : 'gray.600');
  const color = useColorModeValue(hasAccent ? 'white' : 'black', hasAccent ? 'black' : 'white');
  const bgColor = useColorModeValue('black', 'white');
  const bg = hasAccent ? bgColor : undefined;

  if (name && isHidden) {
    return null;
  }

  const isReadonlyCheckbox = isReadOnly || readonly;

  return (
    <>
      <Grid
        templateColumns={['1fr', '1fr', '2fr 1fr']}
        templateRows={['1fr auto', '1fr auto', '1fr auto']}
        templateAreas={[
          '"title content"\n"subtitle content"',
          '"title content"\n"subtitle content"',
          '"title content"\n"subtitle content"',
        ]}
        columnGap={4}
        rowGap={[2, 2, 0]}
        py={3}
        px={3}
        opacity={isInternalHidden ? 0.5 : 1}
        bg={bg}
        borderRadius={8}
      >
        <GridItem area="title" display="flex" alignItems="center">
          {onChange ? (
            <Checkbox
              isChecked={enabled}
              onChange={onChange}
              isDisabled={isReadonlyCheckbox}
              colorScheme="brand"
              iconColor="white"
              _focusVisible={{ boxShadow: '0 0 3px brand.500' }}
            >
              <Text variant="label" color={color}>
                {href ? (
                  <Link href={href} target="_blank" rel="noopener noreferrer" fontWeight={400}>
                    {title}
                  </Link>
                ) : (
                  title
                )}
              </Text>
            </Checkbox>
          ) : (
            <Text variant={highlighted ? 'highlighted-label' : 'label'} color={color}>
              {href ? (
                <Link href={href} target="_blank" rel="noopener noreferrer" fontWeight={400}>
                  {title}
                </Link>
              ) : (
                title
              )}
            </Text>
          )}
          <Box ml={2} mt={-0.5}>
            <Info text={description} />
          </Box>
        </GridItem>
        <GridItem area="subtitle">
          <Text variant="subtitle" color={detailColor}>
            {subtitle}
          </Text>
        </GridItem>
        <GridItem>{name && <OfferToggle name={name} isOption={Boolean(onChange)} />}</GridItem>
        <GridItem area="content">{children}</GridItem>
      </Grid>
      <Divider _last={{ display: 'none' }} variant="amount" />
    </>
  );
};
